﻿/// <reference path="../@types/alertify/index.d.ts" />
import { global, isEmpty } from "../Common/saop-common";
import { SaopServicesCore } from "../Core/saop-services-core";
import * as moment from '../../node_modules/moment/moment';
import { SaopRegUraDogodekView } from "./saop-regUraDogodek-view";
import { SaopRegCurrentEventView } from './saop-regCurrentEvent-view'

export class SaopRegTodayEventsView extends SaopRegUraDogodekView {
    public regCurrentEventView:SaopRegCurrentEventView;
    //
    private _saopServicesCore :SaopServicesCore;

    constructor(viewId:string,libName:string = "") {
        super(viewId,libName);
        this._saopServicesCore = new SaopServicesCore();
        //
        this.refreshData = this.refreshListData.bind(this);
        //
        this.regCurrentEventView = new SaopRegCurrentEventView("#regAcctionButtonsView",libName+".regCurrentEventView");
        this.regCurrentEventView.afterSubmitSuccess = this.refreshListData.bind(this);   
    }

    initView(): void {
        global.ConsoleLogDegug("initView");
        this.initClock();
        this.onReadyInitRegUraDogodekView();
        this.onReadyInitDogodkiDanasnjiPartialView();        
    }

    bindGridEvents(){
        global.ConsoleLogDegug("bindGridEvents");
        let _this = this;
        //
        $("#regUraDogodekAddRecord").off("click");
        $("#regUraDogodekAddRecord").on('click', function(row){
            global.ConsoleLogDegug("regUraDogodekAddRecord.click");
            let currentDate = moment(new Date()).format("DD.MM.YYYY")
            _this.addRecord({ SelectedDay:currentDate});
        });         
        //
        $(".regDogodkiDanasnjiTable_edit").off("click");
        $(".regDogodkiDanasnjiTable_edit").on('click', function(row){
            global.ConsoleLogDegug("regDogodkiDanasnjiTable_edit.click");
            let _btnEdit = row.currentTarget;
            let btnEnabled = _btnEdit.getAttribute("data-enabled");
            if (btnEnabled.toString().toLowerCase() == "true") {
                let zacetek = _btnEdit.getAttribute("data-zacetek");
                let konec = _btnEdit.getAttribute("data-konec");
                _this.editRecord({ idStart: zacetek, idEnd: konec },_this);
            }            
        });        
        //
        $(".regDogodkiDanasnjiTable_delete").off("click");
        $(".regDogodkiDanasnjiTable_delete").on('click', function(row){
            global.ConsoleLogDegug("regDogodkiDanasnjiTable_delete.click");
            let _btnDelete = row.currentTarget;
            let btnEnabled = _btnDelete.getAttribute("data-enabled");
            if (btnEnabled.toString().toLowerCase() == "true") {
                let zacetek = _btnDelete.getAttribute("data-zacetek");
                let konec = _btnDelete.getAttribute("data-konec");
                // //
                let deleteModalFormTitle = _this.translationService.translate("Brisanje");
                let deleteModalFormBody = _this.translationService.translate("Ali ste prepričani, da želite izbrisati dogodek?");
                //
                _this.deleteRecord(deleteModalFormTitle,deleteModalFormBody,{ idStart: zacetek, idEnd: konec });
            }
        });         
    }

    afterRefreshData():void {
        global.ConsoleLogDegug("SaopRegTodayEventsView.afterRefreshListData");
    }

    refreshListData(trigger:boolean = true):void {
        global.ConsoleLogDegug("SaopRegTodayEventsView.refreshView");
        let _this = this;

        this._saopServicesCore.afterDone = function(response){
            $("#regTodayRecordForm").html(response);
            _this.bindGridEvents();
            _this.regCurrentEventView.bindFormAjaxEvents();   
        };
        this._saopServicesCore.ExecuteAction({
            action:"REGTodayEvents/MainFormPartial" 
            ,data:null
            ,type:"GET"
            ,spinnerDivId:"#regTodayRecordLoading"
        });       

        if (trigger && this.afterRefreshData != null){ this.afterRefreshData(); }
    }

    onReadyInitRegUraDogodekView(): void {
        global.ConsoleLogDegug("onReadyInitRegUraDogodekView");

        let message = <string>($("#OnLoadMessage").val());
        if (message) {
            alertify.message(message);
        }

        this.refreshListData();
    }

    initClock(): void {
        global.ConsoleLogDegug("onReadyInitDogodkiTekociPartialView");

        this.updateClock();

        setInterval(this.updateClock, 1000)
    }

    updateClock(): void {
        let clockElement = $("#clock");

        let currentTime = new Date();
        let currentHours = currentTime.getHours();
        let currentMinutes = currentTime.getMinutes();
        let currentSeconds = currentTime.getSeconds();

        // Pad the hours, minutes and seconds with leading zeros, if required
        let currentHoursStr = (currentHours < 10 ? "0" : "") + currentHours;
        let currentMinutesStr = (currentMinutes < 10 ? "0" : "") + currentMinutes;
        let currentSecondsStr = (currentSeconds < 10 ? "0" : "") + currentSeconds;

        // Compose the string for display
        var currentTimeString = currentHoursStr + ":" + currentMinutesStr + ":" + currentSecondsStr;

        clockElement.html(currentTimeString);
    }

    onReadyInitDogodkiDanasnjiPartialView(): void {
        global.ConsoleLogDegug("onReadyInitDogodkiDanasnjiPartialView");

        $("#regDogodkiDanasnjiTable tbody tr").each(
            function (i, row) {
                // koda zakomentirana, ker ni potrebna.
                // Če ni zakomentirana, se pojavijo težave pri refresh view "Dogodki na dan", in se polje "Konec" obarva rdece zaradi spodnje kode

                //var $actualRow = $(row);

                //if ($actualRow.children('td:nth-child(2)').text() == "") {
                //    $(this).children('td:nth-child(6)').css("background-color", '#FAC0BE');
                //} else if ($actualRow.children('td:nth-child(1)').text() == "") {
                //    $(this).children('td:nth-child(5)').css("background-color", '#FAC0BE');
                //}
            });

        $("#webgrid1 th:nth-child(1)").hide();
        $("#webgrid1 th:nth-child(2)").hide();
    }

}


