﻿/// <reference path="../@types/alertify/index.d.ts" />
import { global,isUnDefined, formatDate, setModalFormZIndex, isEmpty } from "../Common/saop-common";
import { SaopWfAction } from "../core/saop-view-form";
import { SaopServicesCore } from "../Core/saop-services-core";
import { SaopRegUraDogodekView } from "./saop-regUraDogodek-view";
import { SaopRegDetailsTableView } from "./saop-regUra-details-table-view";
import { SaopRegUraDanView } from "./saop-regUraDan-view"
import { SaopSelectMonthYear } from "../Components/saop-select-month-year";

export class SaopRegView {
    private _libName:string;
    private _saopRegUraDogodekView1:SaopRegUraDogodekView;
    private _periodCheckBoxId: string;
    private saopRegDetailsTableView: SaopRegDetailsTableView;
    private _saopRegUraDanView:SaopRegUraDanView;
    public _saopSelectMonthYear: SaopSelectMonthYear;

    constructor(libName:string = "") {
        this._libName = libName;
        this._saopRegUraDogodekView1 = new SaopRegUraDogodekView("#regDogodekView0",this._libName);
        this._saopRegUraDogodekView1.refreshData = this.refreshRegUraBrowseView.bind(this);
        //this._saopServicesCore = new SaopServicesCore();
        this._periodCheckBoxId = "#cbperiodCheckBox";
        this.saopRegDetailsTableView = new SaopRegDetailsTableView(this._libName + ".saopRegDetailsTableView", "#reg-grid");
        //
        this._saopRegUraDanView = new SaopRegUraDanView("#regDogodekView0",this._libName + "._saopRegUraDanView");
        this._saopRegUraDanView.afterRefreshData = this.afterRefreshRegUraDanView.bind(this);

        this._saopSelectMonthYear = new SaopSelectMonthYear("", "#selectMesecLeto", this._libName + "._saopSelectMonthYear");
        this._saopSelectMonthYear.afterMonthYearValuesChanged = this.afterSaopMonthYearValuesChanged.bind(this);
    } 

    initView(): void {
        global.ConsoleLogDegug("initRegUraBrowseView");
        let _this = this;
        let _saopServicesCore = new SaopServicesCore();
        _saopServicesCore.afterDone = function(response){
            $("#tabRegBr").html(response);
            _this.initRegUraControls();
            _this.refreshButtonsState();
            _this.refreshTableData();    
        };
        _saopServicesCore.ExecuteAction({
            action:"REGMonthEvents/MainFormPartial"
            ,type:"GET"
            ,spinnerDivId:"#saopLoadingtabRegBr"
        });
    }

    initRegUraControls(): void {
        global.ConsoleLogDegug("initRegUraControls");
        let _this = this;

        $('#btnAddRecord').click(function () {
            global.ConsoleLogDegug("btnAddRecord");
            let _today = formatDate(new Date(), "d.mm.yyyy");
            let _data = { SelectedDay: _today }

            _this._saopRegUraDogodekView1.onErrorCallback = "";
            _this._saopRegUraDogodekView1.showViewForm(SaopWfAction.wfaAddRecord, _data, null);
        });

        $('#btnSendMail').click(function () {
            _this.sendSummaryHours();
        });
      
        this._saopSelectMonthYear.init();
    }

    sendSummaryHours(): void {
        global.ConsoleLogDegug("sendSummaryHours");
        let _this = this;

        let selectedMonthYear = this._saopSelectMonthYear.getSelectedMonthYear();
        let params = { leto: selectedMonthYear.leto, mesec: selectedMonthYear.mesec };

        let _saopServicesCore = new SaopServicesCore();
        _saopServicesCore.afterDone = function(response){
            if (response.success) {
                alertify.message(response.message);
            } else {
                $("#vsMonthlyRecord").html(response.viewContent);
            }
        };
        _saopServicesCore.ExecuteAction({
            action: "REGMonthEvents/SummaryHours"
            , data: params
            , type: "GET"
        });           
    }

    afterRefreshData():void{
        global.ConsoleLogDegug("SaopRegView.afterRefreshData");
    }

    refreshRegUraBrowseView(trigger:boolean = true):void{
        global.ConsoleLogDegug("refreshRegUraBrowseView");
        let _this = this;

        let selectedMonthYear = this._saopSelectMonthYear.getSelectedMonthYear();
        let params = { leto: selectedMonthYear.leto, mesec: selectedMonthYear.mesec };

        let _saopServicesCore = new SaopServicesCore();
        _saopServicesCore.afterDone = function(response){
            $("#reg-grid").html(response);
            _this.refreshButtonsState();
            _this.refreshTableData(); 
            _this.refreshCaptions();  

            _this._saopSelectMonthYear.setRefreshingRecordsComplete();
         };
        _saopServicesCore.ExecuteAction({
            action:"REGMonthEvents/GetRegUraTableView"
            , data: params
            , type: "GET"
            ,spinnerDivId:"#saopLoadingRegGrid"
        });

        if (trigger && this.afterRefreshData != null){ this.afterRefreshData(); }
    }

    refreshCaptions(): void {
        global.ConsoleLogDegug("refreshCaptions");
        let _captionMonthName = $('#captionMonthName');
        if (_captionMonthName.length){
            $('#caption').html(_captionMonthName.val().toString());
        }
        
    }

    refreshButtonsState(): void {
        global.ConsoleLogDegug("refreshButtonsState");
        if (typeof $("#btnAddRecordEnabled").val() === 'undefined') {
            return;
        }
        let _btnAddRecordEnabled = $("#btnAddRecordEnabled").val().toString();
        if (!isUnDefined(_btnAddRecordEnabled) && _btnAddRecordEnabled.toLowerCase() == "true") {
            $('#btnAddRecord').removeAttr("disabled");            
        } else {
            $('#btnAddRecord').attr("disabled", "true");
        }
    }

    refreshTableData(setClickEvents: boolean = true): void {
        global.ConsoleLogDegug("RegUraBrowseView->refreshTableData");
        let _this = this;
        $("#reg-grid tbody tr").each(
            function (i, row) {
                var $actualRow = $(row);
                var $data = $actualRow.children('td:nth-child(1)').text();
                var $wd = $data.substring(0,1);
                var $col = $data.substring(1,2);
                var $adate = $data.substring(2,3);
                var $xy = $actualRow.children('td:nth-child(4)');
                let statusCaption = $xy.html();
                $xy.html("");

                $xy.css('background-repeat', 'no-repeat');
                $xy.css('background-position', 'right');

                if ($wd == "N") {
                    $actualRow.css('background-color', '#F7F7F7');
                } else if ($adate == "B") {
                } else if ($col == "R"){
                    $xy.append('<sapn class="saop-cl-red saop-img-exclamation-mark-solid-cf" style="margin-left: 10%;"></sapn> ');
                } else if ($col == "Y") {
                    $xy.append('<sapn class="saop-cl-orange saop-img-exclamation-mark-solid-cf" style="margin-left: 10%;"></sapn> ');
                } else if ($col == "W"  ) {
                    $xy.append('<sapn class="saop-cl-green saop-img-check-mark-solid-cf" style="margin-left: 10%;"></sapn> ');
                }

                if ($actualRow.children('td:nth-child(1)').text() == "R") {
                    $xy.append('<sapn class="saop-cl-red img-exclamation-mark-solid si-s" style="margin-left: 10%;"></sapn>');
                } else if ($actualRow.children('td:nth-child(1)').text() == "Y") {
                    $xy.append('<sapn class="saop-cl-orange saop-img-exclamation-mark-solid si-s" style="margin-left: 10%;"></sapn>');
                    $xy.css('background-repeat', 'no-repeat');
                    $xy.css('background-position', 'right');
                } else if ($actualRow.children('td:nth-child(1)').text() == "W") {
                    $actualRow.css('background-color', '#F7F7F7');
                } else if ($actualRow.children('td:nth-child(1)').text() == "G") {
                    $xy.append('<sapn class="saop-cl-green saop-img-check-mark-solid si-s" style="margin-left: 10%;"></sapn>');
                }

                $xy.append(statusCaption);
         });

        $("#reg-grid th:nth-child(1)").hide();
        $("#reg-grid td:nth-child(1)").hide();

        let sifraZaposleni = $("#reg-grid #SifraZaposleni").val();

        let selectedMonthYear = this._saopSelectMonthYear.getSelectedMonthYear();
        var d = new Date();
        if (d.getFullYear() == selectedMonthYear.leto && (d.getMonth() + 1) == selectedMonthYear.mesec) {
            // show selected today in table only for current month
            var weekDay = d.getUTCDate();
            $("#reg-grid tr[tabindex=\"" + String(weekDay) + "\"]").addClass("saop-table-selected-row");
            $("#reg-grid tr[tabindex=\"" + String(weekDay) + "\"]").focus();
            $("#reg-grid tr[tabindex=\"" + String(weekDay) + "\"]").blur();
        }

        if (setClickEvents) {
            $("body").off("click", "#reg-grid tr.saop-tr-main td:not(.td-action-details)");
            $("body").on("click", "#reg-grid tr.saop-tr-main td:not(.td-action-details)", function () {
                var SelectedDay = $(this).closest("tr").find("td").eq(2).text()
                let _saopServicesCore = new SaopServicesCore();
                _saopServicesCore.afterDone = function(response){
                    $("#fullscreenModalBody").html(response);
                    $('#saopModalFS').modal('show');
                    setModalFormZIndex('#saopModalFS');
                    _this.bindButtonsEventsView();
                };
                _saopServicesCore.ExecuteAction({
                    action:"REGDayEvents/GetRegUraBrowseViewPV?SelectedDay=" + SelectedDay+"&employeeId="+sifraZaposleni
                    ,type:"GET"
                });

            });

            this.saopRegDetailsTableView.bindGridEvents();
        }
    }

    public onSubmitSuccess(result: any): void {
        this._saopRegUraDogodekView1.onSubmitSuccess(result);
    }

    public bindButtonsEventsView():void {
        global.ConsoleLogDegug("RegUraBrowseView->bindEventsRegUraDanView");
        let _this = this;

        this._saopRegUraDanView.initView();

        let btnFullscreeClose = $("#popupModalFormFS").find("#btnFullscreeClose");
        btnFullscreeClose.off("click",_this.onCloseView);
        btnFullscreeClose.on("click",_this.onCloseView);   

        let btnClose = $("#popupModalFormFS").find("#btnClose");
        btnClose.off("click",_this.onCloseView);
        btnClose.on("click",_this.onCloseView);        

    }

    public afterRefreshRegUraDanView():void{
        global.ConsoleLogDegug("RegUraBrowseView->afterRefreshRegUraDanView");
        this.bindButtonsEventsView();
    }

    public onCloseView():void {
        global.ConsoleLogDegug("RegUraBrowseView->onCloseRegUraDanView");
    }

    public afterSaopMonthYearValuesChanged(leto: number, mesec: number): void {
        global.ConsoleLogDegug("RegUraBrowseView->afterSaopMonthYearValuesChanged");

        this.refreshRegUraBrowseView();
    }
    //
}


