﻿import { global, isEmpty, isUnDefined } from "../Common/saop-common";
import { SaopMultiselect, SaopMultiselectOptions } from "../Components/saop-multiselect";

export class SaopWebGridUnitOrgFilter {
    private libName: string;

    private _selectSkupinaId: string;
    public _selectZaposleniId: string;

    private _selectSkupina: SaopMultiselect;
    public _selectZaposleni: SaopMultiselect;

    public izbraniZaposleni: string = "";

    constructor(libName: string, orgUnitFilterId: string = "") {
        this.libName = libName;

        this._selectSkupinaId = "#OrgUnitFilter" + orgUnitFilterId;
        this._selectZaposleniId = "#EmployeesFilter" + orgUnitFilterId;
    }

    initFilterControls(): void {
        let _this = this;

        const options: SaopMultiselectOptions = {
            selectAll: false
        };
        this._selectSkupina = new SaopMultiselect(this.libName, options);
        this._selectSkupina.afterChangeCommonEvent = _this.filterSelectSkupinaData.bind(_this);
        this._selectSkupina.init(this._selectSkupinaId, "Skupina za prikaz");
        var data = _this._selectSkupina.selectedCB[0].getAttribute("data-default");
        var valArr = data.split(",");

        this._selectZaposleni = new SaopMultiselect();
        this._selectZaposleni.afterChangeCommonEvent = this.filterTableRows.bind(this);
        this._selectZaposleni.init(this._selectZaposleniId, "Zaposleni za prikaz");

        this._selectSkupina.setSelectValues(valArr);
    }

    filterSelectSkupinaData(element: any) {
        global.ConsoleLogDegug(this.libName + ".filterSelectSkupinaData");

        // preberemo, katere sifre zaposlenih sodijo v izbrane skupine
        let izbraneSkupine = <string>(element.val().toString());
        let izbraneSifreZaposlenih: string[] = null;

        if (izbraneSkupine != null && izbraneSkupine.length > 0) {
            let izbraneSifreZaposlenihStr = this.getSelestedEmployeesIds(izbraneSkupine);
            izbraneSifreZaposlenih = izbraneSifreZaposlenihStr.split(",");
        }

        if (this._selectZaposleni != null) {
            this._selectZaposleni.setSelectValues(izbraneSifreZaposlenih);
            this.izbraniZaposleni = this._selectZaposleni.selectedCB.val().toString();
        }
    }

    filterTableRows() {
        global.ConsoleLogDegug(this.libName + ".filterTableRows");

        // filter glede na izbrane sifre zaposlenih
        if (this._selectZaposleni) {
            this.filterSelectZaposleniData(this._selectZaposleni.selectedCB);
        }
    }

    filterSelectZaposleniData(element: any) {
        global.ConsoleLogDegug(this.libName + ".filterSelectZaposleniData");

        // preberemo, katere sifre zaposlenih sodijo v izbrane skupine
        let izbraneSifreZaposlenih = <string>(element.val().toString());

        // filter vrstic
        this.filterZaposleniTableRows(izbraneSifreZaposlenih);
    }


    getSelestedEmployeesIds(izbraneSkupine: string): string {
        global.ConsoleLogDegug(this.libName + ".getSelestedEmployeesIds");

        let selectedEmployeesIds: string = "";
        let arrayIzbraneSkupine = izbraneSkupine.split(',');
        for (var ind = 0; ind < arrayIzbraneSkupine.length; ind++) {
            if (arrayIzbraneSkupine[ind] != null && arrayIzbraneSkupine[ind].length > 0) {
                // preberemo seznam zaposlenih iz hidden text html elementov
                let hiddetTextHtmlId = "#sifraEnoteOrgStrukture" + arrayIzbraneSkupine[ind].trim();
                let valu = $(hiddetTextHtmlId).val();
                valu = valu.toString().trim()
                selectedEmployeesIds = selectedEmployeesIds + valu;
                if (ind < (arrayIzbraneSkupine.length - 1)) {
                    selectedEmployeesIds = selectedEmployeesIds + ",";
                }
            }
        }
        return selectedEmployeesIds;
    }

    filterZaposleniTableRows(filterZaposleni: string) {
        global.ConsoleLogDegug(this.libName + ".filterZaposleniTableRows");

        //// Declare variables
        //var table, tr, _rowId, i, txtValue;
        //table = document.getElementById(this._tableName);
        //tr = table.getElementsByTagName("tr");
        //// Loop through all table rows, and hide those who don't match the search query
        //for (i = 0; i < tr.length; i++) {
        //    _rowId = null;
        //    let _trId = $(tr[i]).attr("data-sifra-zaposleni");
        //    if (!isUnDefined(_trId)) {
        //        _rowId = _trId.toString()
        //    }

        //    if (_rowId) {
        //        txtValue = _rowId.toUpperCase();
        //        if (isEmpty(filterZaposleni)) {
        //            $(tr[i]).addClass("element-hidden");
        //        } else if (filterZaposleni.toUpperCase().indexOf(txtValue) > -1) {
        //            $(tr[i]).removeClass("element-hidden");
        //        } else {
        //            $(tr[i]).addClass("element-hidden");
        //        }
        //    }
        //}
    }
}






