﻿import { global, isEmpty, isUnDefined } from "../Common/saop-common";
import { SaopServicesCore } from "../Core/saop-services-core";
import { SaopCalendar,SaopCalendarNavigation } from "../Components/saop-calendar";
import { SaopAbsenceConfirmationDetailsView } from "./saop-absenceConfirmation-details-view";
import { SaopRegKeZapDopustiView } from "../REG/saop-reg-keZapDopusti-view";
import { SaopMultiselect, SaopMultiselectOptions } from "../Components/saop-multiselect";
import { SaopPopover } from "../Components/saop-popover";
import { SaopEmployeeLeaveCalendar } from "../AbsenceConfirmation/saop-employeeLeaveCalendar-view";

export class SaopAbsenceConfirmationCalendarView extends SaopCalendar {
    private saopAbsenceConfirmationDetailsView: SaopAbsenceConfirmationDetailsView;
    private saopRegKeZapDopustiView: SaopRegKeZapDopustiView;
    private _lastCellId :string;
    private _lastCategoryId: string;
    private _saopLeftSideAdditionalControlsServicesCore: SaopServicesCore;
    private _saopRightSideAdditionalControlsServicesCore: SaopServicesCore;

    private _selectSkupina: SaopMultiselect;
    private _selectSkupinaMobileFilter: SaopMultiselect;
    private _selectCategoryMobileFilter: SaopMultiselect;
    private _saopPopoverFilter: SaopPopover;

    private _saopRegKeZapDopustiView: SaopEmployeeLeaveCalendar;
    //
    constructor(libName: string = "") {
        super(libName);
        this.saopAbsenceConfirmationDetailsView = new SaopAbsenceConfirmationDetailsView("#absenceConfirmationDetailsView0", libName + ".saopAbsenceConfirmationDetailsView");
        this.saopAbsenceConfirmationDetailsView.refreshDataExtended = this.refreshDataAfterUpdate.bind(this);
        this.saopRegKeZapDopustiView = new SaopRegKeZapDopustiView("#regKeZapDopustiView0", libName + ".saopRegKeZapDopustiView");
        this.saopRegKeZapDopustiView.refreshData = this.refreshDataAfterUpdate.bind(this);
        this._saopLeftSideAdditionalControlsServicesCore = new SaopServicesCore();
        this._saopRightSideAdditionalControlsServicesCore = new SaopServicesCore();

        this._saopPopoverFilter = new SaopPopover('.daygrid-event');
        this._saopPopoverFilter.afterPopoverShow = this.afterPopoverMobileFilterShow.bind(this);

        this._saopRegKeZapDopustiView = new SaopEmployeeLeaveCalendar('#keEmployeeLeaveCalendar0', libName + ".saopRegKeZapDopustiView");
    }

    initView() {
        super.initView({controller:"AbsenceConfirmation",action:"Calendar",partialRender:true});
        let _this = this;
        $(document).on('click', '.popoverLink', function (e:any) {
            let _popoverId = "#"+e.target.id;
            let _categoryId = "#"+$(_popoverId).attr("data-categoryId");
            let _cellId = "#"+$(_popoverId).attr("data-cellId");
            let _link = $(_popoverId).attr("link");
            const _data = JSON.parse(_link);
            _this.showRecordDetails(_categoryId,_cellId,_data);
        });

        $(document).on('click', '.categoryLink', function (e: any) {
            let _link = $("#" + e.target.id).attr("link");
            if (_link == null) {
                return;
            }
            const _data = JSON.parse(_link);

            _this.showEmployeeLeaveCalendar(_data.sifraZaposleni, _data.nazivZaposleni);
        });

        $(document).on('click', '#btnAddConfirmedAbsence', function (e: any) {
            let elementCtrl = $("#" + e.target.id);
            let formTitle = elementCtrl.data("formtitle");
            _this.showAddAbsenceForm(formTitle, "");
        });

        // dodamo Css v categoriesDiv za potrebe responsive
        let categoriesDiv = $("#categoriesDiv");
        if (categoriesDiv != null) {
            let customCss = "d-none-mobile";
            if (!categoriesDiv.hasClass(customCss)) {
                categoriesDiv.addClass(customCss);
            }
        }

        // prikazemo dodatne header controle za AbsenceConfirmation koledar, ker je SaopCalendar splosna kontrola
        this.showAbsenceConfirmationCalendarLeftSideAdditionalControls();
        this.showAbsenceConfirmationCalendarRightSideAdditionalControls();
    }

    bindDivs(){
        super.bindDivs();
        this.formData.addClass("absences-calendar");
        this.formData.addClass("table-column-holder");
        this.formData.addClass("saop-table-fixhead");
        // 
    }

    showRecordDetails(categoryId:string,cellId:string,detailsData: any): void {
        this._lastCellId = cellId;
        this._lastCategoryId = categoryId;
        this.saopAbsenceConfirmationDetailsView.showForm(detailsData, detailsData.nazivZaposleni);
    }

    showAddAbsenceForm(title: string, sifraZaposleni: string): void {
        global.ConsoleLogDegug("SaopAbsenceConfirmationCalendarView.showAddAbsenceForm");
        this.saopRegKeZapDopustiView.title = title;
        this.saopRegKeZapDopustiView.addRecord({ selectSifraZaposleni: true, sifraZaposleni: sifraZaposleni, avtomatskaPotrditev: true });
    }

    refreshDataAfterUpdate(){
        this.refreshData(SaopCalendarNavigation.refreshTableData,this._lastCategoryId);
    }

    afterRefreshTableData(e:any){
        global.ConsoleLogDegug("SaopAbsenceConfirmationCalendarView.afterRefreshTableData");

        this.bindDivs();

    }

    showAbsenceConfirmationCalendarRightSideAdditionalControls(): void {
        const _this = this;

        this._saopRightSideAdditionalControlsServicesCore.afterDone = function (response) {
            $("#" + _this.getHeaderControlGroupId()).append(response);
            _this._saopPopoverFilter.bindPopoverEvents();
        };
        this._saopRightSideAdditionalControlsServicesCore.ExecuteAction({
            action: "AbsenceConfirmation/GetCalendarRightSideAdditionalControls"
            , data: null
            , type: "GET"
            , spinnerDivId: "#calendarFormHeaderControlGroupLoading"
        });
    }

    showAbsenceConfirmationCalendarLeftSideAdditionalControls(): void {
        const _this = this;

        this._saopLeftSideAdditionalControlsServicesCore.afterDone = function (response) {
            $("#" + _this.getHeaderControlGroupId()).prepend(response);

            const options: SaopMultiselectOptions = {
                selectAll: false
            };
            _this._selectSkupina = new SaopMultiselect(this.libname, options);
            _this._selectSkupina.init("#EnoteOrgStrukture", "Skupina za prikaz");
            _this._selectSkupina.afterChangeCommonEvent = _this.filterSelectSkupinaData.bind(_this);

            var data = _this._selectSkupina.selectedCB[0].getAttribute("data-default");
            var valArr = data.split(",");
            _this._selectSkupina.setSelectValues(valArr);
        };
        this._saopLeftSideAdditionalControlsServicesCore.ExecuteAction({
            action: "AbsenceConfirmation/GetCalendarLeftSideAdditionalControls"
            , data: null
            , type: "GET"
            , spinnerDivId: "#calendarFormHeaderControlGroupLoading"
        });
    }

    filterSelectSkupinaData(element: any) {
        global.ConsoleLogDegug("SaopAbsenceConfirmationCalendarView.filterSelectSkupinaData");

        // preberemo, katere sifre zaposlenih sodijo v izbrane skupine
        let izbraneSkupine = <string>(element.val().toString());
        let izbraneSifreZaposlenih: string[] = null;

        if (izbraneSkupine != null && izbraneSkupine.length > 0) {
            let izbraneSifreZaposlenihStr = this.getSelestedEmployeesIds(izbraneSkupine);
            izbraneSifreZaposlenih = izbraneSifreZaposlenihStr.split(",");
        }

        if (this._multiSelectCategory != null) {
            this._multiSelectCategory.setSelectValues(izbraneSifreZaposlenih);
        }
    }

    filterTableRows(filterSkupina: string, filterZaposleni: string) {
        global.ConsoleLogDegug("SaopAbsenceConfirmationCalendarView.filterTableRows");

        // Declare variables
        var table, tr, _rowId, i, txtValue;
        table = document.getElementById("saop-calendar-table");
        tr = table.getElementsByTagName("tr");
        // Loop through all table rows, and hide those who don't match the search query
        for (i = 0; i < tr.length; i++) {
            _rowId = null;
            let _trId = $(tr[i]).attr("data-sifra-zaposleni");
            if (!isUnDefined(_trId)) {
                _rowId = _trId.toString()
            }
            if (_rowId) {
                txtValue = _rowId.toUpperCase();
                if (isEmpty(filterZaposleni)) {
                    tr[i].style.display = "none";
                } else if (filterZaposleni.toUpperCase().indexOf(txtValue) > -1) {
                    // filterZaposleni je izpolnjen, preveri se filterSkupina
                    let dataSkupina = String($(tr[i]).data("sifraenoteorgstrukture"));
                    if (filterSkupina.toUpperCase().indexOf(dataSkupina.toUpperCase()) > -1) {
                        tr[i].style.display = "";
                    }
                    else {
                        tr[i].style.display = "none";
                    }
                } else {
                    tr[i].style.display = "none";
                }
            }
        }
    }

    getSelestedEmployeesIds(izbraneSkupine: string): string {
        global.ConsoleLogDegug("SaopAbsenceConfirmationCalendarView.getSelestedEmployeesIds");

        let selectedEmployeesIds: string = "";
        let arrayIzbraneSkupine = izbraneSkupine.split(',');
        for (var ind = 0; ind < arrayIzbraneSkupine.length; ind++) {
            if (arrayIzbraneSkupine[ind] != null && arrayIzbraneSkupine[ind].length > 0) {
                // preberemo seznam zaposlenih iz hidden text html elementov
                let hiddetTextHtmlId = "#sifraEnoteOrgStrukture" + arrayIzbraneSkupine[ind].trim();
                let valu = $(hiddetTextHtmlId).val();
                valu = valu.toString().trim()
                selectedEmployeesIds = selectedEmployeesIds + valu;
                if (ind < (arrayIzbraneSkupine.length - 1)) {
                    selectedEmployeesIds = selectedEmployeesIds + ",";
                } 
            }
        }
        return selectedEmployeesIds;
    }

    afterPopoverMobileFilterShow(element: HTMLElement): void {
        global.ConsoleLogDegug("SaopAbsenceConfirmationCalendarView.afterPopoverMobileFilterShow");

        let atrContent = element.getAttribute("data-popover-content");
        // preverimo če je popup calendar filter
        if (atrContent === "#popOverCalendarFilter") {

            this._selectSkupinaMobileFilter = new SaopMultiselect();
            this._selectSkupinaMobileFilter.init("div.popover.show #EnoteOrgStruktureFilter", "Skupina za prikaz");
            // metoda this.setSelectSkupinaMobileFilterValues se mora izvesti pred this._selectSkupinaMobileFilter.afterChangeCommonEvent
            // da se se ne izvede metoda this.selectSkupinaMobileFilterChanged, ker se v this.setSelectSkupinaMobileFilterValues
            // nastavijo vrednosti v klasu SaopMultiselect in posledicno se klice tudi afterChangeCommonEvent event
            this.setSelectSkupinaMobileFilterValues();
            this._selectSkupinaMobileFilter.afterChangeCommonEvent = this.selectSkupinaMobileFilterChanged.bind(this);

            this._selectCategoryMobileFilter = new SaopMultiselect();
            this._selectCategoryMobileFilter.init("div.popover.show #CategoriesFilter", "Zaposleni za prikaz");
            // metoda this.setSelectCategoryMobileFilterValues se mora izvesti pred this._selectSkupinaMobileFilter.afterChangeCommonEvent
            // da se se ne izvede metoda this.selectSkupinaMobileFilterChanged, ker se v this.setSelectSkupinaMobileFilterValues
            // nastavijo vrednosti v klasu SaopMultiselect in posledicno se klice tudi afterChangeCommonEvent event
            this.setSelectCategoryMobileFilterValues();
            this._selectCategoryMobileFilter.afterChangeCommonEvent = this.selectCategoryMobileFilterChanged.bind(this);

            // dodamo custom css za filter
            let filterPopoverDiv = $("div.popover.show");
            if (filterPopoverDiv != null) {
                let customCss = "calendar-filter";
                if (!filterPopoverDiv.hasClass(customCss)) {
                    filterPopoverDiv.addClass(customCss);
                }
            }

            // dodamo custom css za filter
            let filterPopoverBodyDiv = $("div.popover.show").find("div.popover-body");
            if (filterPopoverBodyDiv != null) {
                let customCss = "calendar-filter-body";
                if (!filterPopoverBodyDiv.hasClass(customCss)) {
                    filterPopoverBodyDiv.addClass(customCss);
                }
            }
        
            // prikazi filter okno
            let filterBody = $("div.popover.show").find("#popOverCalendarFilterBody");
            filterBody.show();
        }
    }

    setSelectSkupinaMobileFilterValues() {
        global.ConsoleLogDegug("SaopAbsenceConfirmationCalendarView.setSelectSkupinaMobileFilterValues");

        let skupine = <string[]>(this._selectSkupina.selectedCB.val());
        this._selectSkupinaMobileFilter.setSelectValues(skupine);
    }

    setSelectCategoryMobileFilterValues() {
        global.ConsoleLogDegug("SaopAbsenceConfirmationCalendarView.setSelectCategoryMobileFilterValues");

        let zaposleni = <string[]>(this._multiSelectCategory.selectedCB.val());
        this._selectCategoryMobileFilter.setSelectValues(zaposleni);
    }

    selectSkupinaMobileFilterChanged(element: any) {
        global.ConsoleLogDegug("SaopAbsenceConfirmationCalendarView.selectSkupinaMobileFilterChanged");

        // shranimo vrednosti v desktop multiSelectSkupina element
        // avtomatsko se bodo nastavili tudi zaposleni v desktop filter elementu
        let izbraneSkupine = <string[]>(this._selectSkupinaMobileFilter.selectedCB.val());
        this._selectSkupina.setSelectValues(izbraneSkupine);

        // preberemo oznacene zaposlene v desktop filter elementu
        // in nastavimo tudi zaposlene v mobile filter elementu
        this.setSelectCategoryMobileFilterValues();
    }

    selectCategoryMobileFilterChanged(element: any) {
        global.ConsoleLogDegug("SaopAbsenceConfirmationCalendarView.selectCategoryMobileFilterChanged");

        // shranimo vrednosti v desktop Category element
        let izbraniZaposleni = <string[]>(this._selectCategoryMobileFilter.selectedCB.val());
        this._multiSelectCategory.setSelectValues(izbraniZaposleni);
    }

    showEmployeeLeaveCalendar(sifraZaposleni: string, nazivZaposleni: string): void {
        global.ConsoleLogDegug("SaopAbsenceConfirmationCalendarView.showSaopAbsenceConfirmationCalendarView");

        this._saopRegKeZapDopustiView._sifraZaposleni = sifraZaposleni;
        this._saopRegKeZapDopustiView._nazivZaposleni = nazivZaposleni;
        this._saopRegKeZapDopustiView.setFullTitle();
        this._saopRegKeZapDopustiView.recordDetails({ sifraZaposleni: sifraZaposleni });
        this._saopRegKeZapDopustiView.SetToFullScreen();
    }
}


